import React from 'react';
import JobDetailContainer from './styles';

const JobDetail: React.FC = () => (
  <JobDetailContainer>
    <div id="jobs-detail" className="PSOFOShop container">
      <div className="row">
        <div className="twelve columns">
          <div id="lumesseJobDetailWidget" />
        </div>
      </div>
    </div>
  </JobDetailContainer>
);

export default React.memo(JobDetail);
