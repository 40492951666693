import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { fonts } from '@rsa-digital/evo-shared-components/theme/RSA/fonts';
import { layout } from '@rsa-digital/evo-shared-components/theme/RSA/layout';
import styled from 'styled-components';
import talentLinkGlobalStyles, { primaryButton, secondaryButton } from '../styles';

const JobDetailContainer = styled.div`
  ${talentLinkGlobalStyles};

  #jobs-detail {
    margin-top: ${spacing(8)};
  }

  h1 {
    ${fonts.headingMedium};
  }

  #lumesseJobDetailWidget {
    dt {
      ${fonts.headingXSmall};
      margin-top: ${spacing(2)};
    }

    a[data-lumesse-jd-action='back'] {
    }
  }

  #lumesseJobDetailWidget > div[role='navigation'] {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    a {
      margin: ${spacing(1)} ${spacing(4)} ${spacing(1)} 0;
      min-width: 150px;
      text-align: center;
    }

    a[data-lumesse-jd-action='back'] {
      ${secondaryButton}
      order: 1;
    }

    a[data-lumesse-jd-action='send2friend'] {
      ${secondaryButton}
      order: 2;
    }

    a[data-lumesse-jd-action='print'] {
      ${secondaryButton}
      order: 3;
    }

    a[data-lumesse-jd-action='apply'] {
      ${primaryButton}
      order: 4;
    }

    @media (min-width: ${layout.tabletPortrait.breakpointMinPx}px) {
      a {
        min-width: unset;
      }

      a[data-lumesse-jd-action='send2friend'] {
        padding-top: ${spacing(0.5)};
        padding-bottom: ${spacing(0.5)};
      }

      a[data-lumesse-jd-action='print'] {
        padding-top: ${spacing(0.5)};
        padding-bottom: ${spacing(0.5)};
      }
    }
  }
`;

export default JobDetailContainer;
